<template>
  <v-simple-table class="elevation-1" style=" box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); margin-top: 10px; width: 500px; border: solid 1px whitesmoke;">
    <template v-slot:default>
      <tbody>
        <!-- <tr v-for="item in desserts" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.calories }}</td>
          </tr> -->
        <tr>
          <td>Customer:</td>
          <td>{{ workorder.customer | isNull }}</td>
        </tr>
        <tr>
          <td>Assembly Item:</td>
          <td>{{ workorder.assembly_item | isNull }}</td>
        </tr>
        <tr>
          <td>Item Description:</td>
          <td>{{ workorder.item_description | isNull }}</td>
        </tr>
        <tr>
          <td>Memo:</td>
          <td>{{ workorder.memo | isNull }}</td>
        </tr>
        <tr>
          <td>Rush:</td>
          <td>{{ workorder.is_rush | isNull }}</td>
        </tr>
        <tr>
          <td>Quantity:</td>
          <td>{{ workorder.quantity | isNull }}</td>
        </tr>
        <tr>
          <td>Location:</td>
          <td>{{ workorder.location | isNull }}</td>
        </tr>
        <tr>
          <td>Seat Bins:</td>
          <td>{{ workorder.seat_bins | isNull }}</td>
        </tr>
        <tr>
          <td>Date Released:</td>
          <td>{{ workorder.date_released | isNull }}</td>
        </tr>
        <tr>
          <td>Order Comments:</td>
          <td>{{ workorder.order_comments | isNull }}</td>
        </tr>
        <tr>
          <td><b>Assigned To:</b></td>
          <td>{{ workorder.assigned_to | isNull }}</td>
        </tr>
        <tr style="border: 1px solid black;">
          <td><b>Current Operation:</b></td>
          <td>{{ workorder.current_op | isNull }}</td> 
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: ["workorder"],
  filters: {
    isNull: function(value) {
      if (!value) return "-";
      else return value;
    },
  },
};
</script>
<style lang="scss"></style>
