<template>
  <div class="base-timer">
    <!-- <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed"
          cx="50"
          cy="50"
          r="45"
        ></circle>
        <path
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>N
      </g>
    </svg> -->
    <v-col>
      <v-row class="base-timer__label">{{ elapsedTime | changeToTime }}</v-row>
      <v-row style="margin-top: 0!important;justify-content: center;">Working Time</v-row>
    </v-col>
    <br />
    <v-simple-table dense style="border: solid 1px whitesmoke;" class="time-table">
      <tbody>
        <tr>
          <td style="border-right: solid 1px whitesmoke;">Standard Time:</td>
          <td>{{ ( (curr_operation || {}).runrate ) | changeToTimeFromSeconds }}</td>
        </tr>
                <!-- <tr>
          <td style="border-right: solid 1px whitesmoke;">Quantity Completed:</td>
          <td style="color: green; font-weight: bold;">{{ quantity_completed }}</td>
        </tr> -->
        <tr>
          <td style="border-right: solid 1px whitesmoke;">WO Quantity:</td>
          <td style="color: crimson; font-weight: bold;">{{ quantity_left }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <!-- style="top: 63px; font-size: 20px!important; position: absolute;" -->
    <!-- <v-col style="top: 80px; font-size: 20px!important;">
      <v-row class="base-timer__label" style="font-size: 20px!important;">Standard Time:{{ (curr_operation || {}).runrate | changeToTimeFromSeconds }}</v-row>
      <v-row style=" font-size: 20px!important;" class="base-timer__label">
        <span>Quantity Left:&nbsp;</span>
        <span style="font-weight: bold; color: crimson;">{{ quantity_left }}</span>
      </v-row>
      <v-row style="font-size: 20px!important;" class="base-timer__label">
        <span> Quantity Completed:&nbsp;</span>
        <span style="font-weight: bold; color: crimson;"> {{ quantity_completed }}</span>
      </v-row>
    </v-col> -->
  </div>
</template>

<script>
const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 600;
const ALERT_THRESHOLD = 300;

const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};

const TIME_LIMIT = 3000;

export default {
  props: ["curr_operation", "quantity_left", "quantity_completed"],
  data() {
    return {
      timePassed: 0,
      timerInterval: null,
      elapsedTime: 0,
      timer: undefined,
    };
  },
  mounted() {},
  filters: {
    changeToTimeFromSeconds(s) {
      // console.log(s);
      var ms = Number(s) * 1000 * 60;
      // 1- Convert to seconds:
      var seconds = ms / 1000;
      // 2- Extract hours:
      var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
      seconds = seconds % 3600; // seconds remaining after extracting hours
      // 3- Extract minutes:
      var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
      // 4- Keep only seconds not extracted to minutes:
      seconds = seconds % 60;
      hours = ("0" + hours).slice(-2);
      minutes = ("0" + minutes).slice(-2);
      seconds = ("0" + seconds).slice(-2);
      if (Number(hours) === 0) return `${minutes}:${seconds}`;
      else return `${hours}:${minutes}:${seconds}`;
    },
    changeToTime(ms) {
      // 1- Convert to seconds:
      ms = Math.round(ms);
      var seconds = Math.round(ms / 1000);
      // 2- Extract hours:
      var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
      seconds = seconds % 3600; // seconds remaining after extracting hours
      // 3- Extract minutes:
      var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
      // 4- Keep only seconds not extracted to minutes:
      seconds = seconds % 60;
      hours = ("0" + hours).slice(-2);
      minutes = ("0" + minutes).slice(-2);
      seconds = ("0" + seconds).slice(-2);
      if (Number(hours) === 0) return `${minutes}:${seconds}`;
      else return `${hours}:${minutes}:${seconds}`;
    },
  },
  computed: {
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },
    formattedElapsedTime() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toUTCString();
      return utc.substr(utc.indexOf(":") - 2, 8);
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / TIME_LIMIT;
      return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
    curr_operation: function(val) {
      if (!val) return;
      var now = new Date(this.curr_operation.now).getTime();
      var stateStateTime = new Date(this.curr_operation.entry_timestamp).getTime();
      var total_pause_time = val.total_pause_time ? val.total_pause_time : 0;
      total_pause_time = total_pause_time * 1000 * 60;
      this.elapsedTime = now - stateStateTime - total_pause_time;
      this.start();
      if (this.curr_operation.state === "Working") {
        this.startTimer();
      }
    },
  },

  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    start() {
      this.timer = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
    },
    stop() {
      clearInterval(this.timer);
    },
    reset() {
      this.elapsedTime = 0;
    },
  },
};
</script>

<style lang="scss">
.time-table td {
  font-size: 20px !important;
}
.base-timer {
  position: relative;
  width: 300px;
  // height: 280px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: rgb(65, 184, 131);
    }

    &.orange {
      color: orange;
    }

    &.red {
      color: red;
    }
  }

  &__label {
    // position: absolute;
    width: 300px;
    // height: 250px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }
}
</style>
