<template>
  <div>
    <div class="flex justify-between">
      <div>
        <img v-if="PRP" src="https://www.prpseats.com/wp-content/uploads/2017/05/New_PRP_Logo_254x62-1.jpg" class="image" />
        <img v-else-if="Allumines" src="https://7795266-sb1.app.netsuite.com/core/media/media.nl?id=4242047&c=7795266_SB1&h=2gkKpQAwoWYTT0aYfP8CWQhQDPYiKfQa5QPVr99PTDoerO8v" class="image" />
        <img v-else-if="MGP" src=https://www.calipercovers.com/wp-content/uploads/2022/10/MGP-FLAG-LOGO-FULL-COLOR.png class="image" />

      </div>
      <div class="bold" style="font-size: 25px; margin-right: 20px;">
        <span> Operator: </span>
        <span>{{ operator_name }}</span>
        <br />
        <span> Work Station: </span>
        <span>{{ workstation }}</span>
        <br />
        <!-- <span style="font-style: italic;"> Scan Badge to Logout</span> -->
        <!-- <button class="logout-button" @click="logout()">Logout</button> -->
        <div style="display: flex; justify-content: center; align-items: center;" v-if="!spinner && !wo_batch">
          <button @click="show_wotable()" v-if="!show_table && !show_releasedWorkOrders" class="bottom-button">Active WO</button>
          <button @click="close_wotable()" v-if="show_table || show_releasedWorkOrders" class="bottom-button" style="background-color: crimson;">Close</button>
        </div>
      </div>
    </div>
    <div v-if="spinner" class="spinner">
      <v-progress-circular indeterminate color="rgb(30 96 174)"></v-progress-circular>
    </div>
    <div v-if="show_mop_backflush" class="blink_me">
      Work Order is BackFlushing
    </div>
    <div v-if="!spinner">
      <!-- style="height: 90vh;" -->
      <div v-if="wo_obj">
        <WorkOrderDisplay :workorder="wo_obj" @logout="logout" @spinner="start_stop_spinner" @spinner_end="start_stop_spinner_end" @removeWO="removeWO" @removeWOCutting="removeWOCutting" @updatedMOP="updatedMOP" ref="wodisplayref" />
      </div>
      <div v-else-if="show_table">
        <ActiveWorkOrders />
      </div>
      <div v-else-if="show_releasedWorkOrders">
        <BatchOrders :entry_time="entry_time" @spinner="start_stop_spinner" :b_func="b_func" @wo_batch_func="wo_batch_func" :batch_start_time="batch_start_time" :batch_id="batch_id" />
      </div>
      <div v-else style="text-align: center;">
        <WorkOrderSearch @workOrder="foundWO" @spinner="start_stop_spinner" @logout="logout" @editShowSuccess="editShowSuccess" />
        <span v-if="show_success" class="bold" style="color: green; margin-top: 50px; height: 50px;"> {{ prev_wo }} Operation Completed</span>
        <!-- <v-row v-if="!start_batch" style="margin-top: 20px;">
          <v-col align-self="center">
            <button style="white-space: nowrap" class="bottom-button" @click="getWorkOrderBatches()">Batch Orders</button>
          </v-col>
        </v-row> -->
        <v-row class="mb-6" style="margin-top: 20px;" v-if="start_batch && workstation_id == '1237674'">
          <v-col cols="12" align-self="center" v-for="n in week_days" :key="n.day" style="">
            <button style="white-space: nowrap" class="bottom-button" :disabled="n.disabled" :class="{ 'gray-background': n.disabled, 'btn-red': n.day == 5 }" @click="getWorkOrderBatches(n.day)">{{ n.name }}</button>
            <span v-if="n.day == 5" style=" margin:auto;">
              <hr style="width: 300px; margin: auto; margin-top:15px; margin-bottom: -10px;" />
            </span>
          </v-col>
        </v-row>
      </div>
      <div>
        <h2 style="color: crimson; text-align: center;">
          {{ workOrderErr }}
        </h2>
      </div>
      <!-- <div>
        <button @click="close_table()" v-if="show_table" class="bottom-button">Bottom button</button>
      </div> -->
    </div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <!-- <div class="card"> -->
        <v-card-text style="text-align:center;">
          <h2 class="text-h5" style="color: red; font-weight: 900;">
            Inventory Issue
          </h2>
        </v-card-text>
        <v-card-text style="text-align:center;">
          <v-icon style="font-size: 50px; color: red; font-weight: 900;">mdi-alert-circle-outline</v-icon>
        </v-card-text>

        <v-card-text style="font-size: 20px; color: red; font-weight: 900;">Issue with Work Order. Bin Location Needs to be updated.</v-card-text>
        <!-- </div> -->
        <v-card-actions>
          <div></div>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" style="border: 10px;" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="released_dialog" persistent max-width="1000">
      <v-card style="margin-top: 20px;">
        <!-- <div style="display: flex; justify-content: center; align-items: center;"> -->
        <!-- <v-col style=""> -->
        <v-card-text>
          <v-row>
            <v-card-title class="text-h5" style="word-break: break-word; text-align: center; margin: auto;font-size: 22px!important;"> Order Type to Release?</v-card-title>
          </v-row>
          <v-row class="justify-space-between" style="margin:auto; width: 90%;">
            <v-col v-for="ord in ordertype" :key="ord.bussines_function" style="margin: auto;">
              <div v-if="!ord.completed">
                <button class="submitBtn" :class="{ 'rushbtn' : ord.bussines_function === 25}" v-if="!ord.started" style="background: green!important; border: none;" @click="runBatch(ord.bussines_function)"> {{ ord.name }}</button>
                <button class="submitBtn" :class="{ 'rushbtn' : ord.bussines_function === 25}" v-else style="background: #F28C28!important; border: none;" @click="endBatch(ord.bussines_function, ord.batch_id, ord.started)"> {{ ord.name }}</button>
              </div>
              <div v-else>
                <button class="submitBtn" style="background-color: grey; border: none;" disabled>{{ ord.name }}</button>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- </v-col> -->
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" style="border: 10px;" text @click="released_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import WorkOrderDisplay from "../components/workorderDisplay";
import WorkOrderSearch from "../components/workOrderSearch";
import ActiveWorkOrders from "../components/ActiveWorkOrders";
import BatchOrders from "../components/BatchOrders";
export default {
  name: "Dashboard",
  components: {
    WorkOrderDisplay,
    WorkOrderSearch,
    ActiveWorkOrders,
    BatchOrders,
  },
  data: () => ({
    wo_batch: false,
    show_releasedWorkOrders: false,
    wo_obj: null,
    spinner: false,
    operator_name: null,
    workstation: null,
    subsidiary: null,
    show_mop_backflush: false,
    workOrderErr: null,
    show_success: false,
    PRP: false,
    Allumines: false,
    MGP: false,
    prev_wo: null,
    dialog: false,
    show_table: false,
    active_wo: [],
    released_dialog: false,
    daysToGet: "",
    start_batch: true,
    batching_wo: true,
    week_days: [
      {
        day: -2,
        name: " LAST Friday",
        disabled: false,
        started: false,
        timeStamp: null,
      },
      {
        day: 1,
        name: "Monday",
        disabled: true,
        started: false,
        timeStamp: null,
      },
      {
        day: 2,
        name: "Tuesday",
        disabled: true,
        started: false,
        timeStamp: null,
      },
      {
        day: 3,
        name: "Wednesday",
        disabled: true,
        started: false,
        timeStamp: null,
      },
      {
        day: 4,
        name: "Thursday",
        disabled: true,
        started: false,
        timeStamp: null,
      },
    ],
    ordertype: [
      {
        bussines_function: 6,
        name: "Inner Liner",
        completed: false,
        started: false,
      },
      {
        bussines_function: 16,
        completed: false,
        name: "Seat Covers",
        started: false,
      },
      {
        bussines_function: 17,
        name: "Bags",
        completed: false,
        started: false,
      },
      {
        bussines_function: 25,
        name: "Rush",
        completed: false,
        started: false,
      },
    ],
    entry_time: null,
    b_func: null,
    batch_start_time: null,
    batch_id: null,
    version: process.env.VUE_APP_VERSION,
    workstation_id: null,
  }),
  computed: {
    ...mapGetters(["active_wo_data"]),
  },
  async created() {
    if (localStorage.wo) {
      this.spinner = true;
      var b = localStorage.getItem("badge_id");
      var wo = localStorage.getItem("wo");
      await this.$store.dispatch("getWorkOrder", { badge_id: b, wo: wo });
    }
    this.$store.subscribe((mutation, state) => {
      this.workOrderErr = null;
      switch (mutation.type) {
        case "setWorkOrder":
          this.spinner = false;
          var wo_type = typeof state.WORKORDER;
          if (state.WORKORDER && wo_type != "string") {
            this.wo_obj = state.WORKORDER;
            localStorage.wo = this.wo_obj.name;
          } else if (typeof state.WORKORDER === "string") {
            this.workOrderErr = state.WORKORDER;
            this.removeWO();
          } else {
            this.workOrderErr = "Invalid Input";
          }
          break;
        case "setMOP":
          // this.removeWO();
          if (mutation.payload === "routing_issue") {
            // this.removeWO();
            // // this.show_mop_backflush = false;
            // // this.start_stop_spinner()
            this.dialog = true;
            // debugger;
            // setTimeout(() => {
            //   alert("Please have adminitration review work order and scan again to complete. Bin Location Needs to be updated.");
            // }, 0);
          }
          // console.log(state.);
          break;
        case "setPause":
          // debugger;
          if (mutation.payload == "qchold") {
            // var b = localStorage.getItem("badge_id");
            // var wo = localStorage.getItem("wo");
            // this.$store.dispatch("getWorkOrder", { badge_id: b, wo: wo });
            this.removeWO();
          } else if (mutation.payload) {
            this.removeWO();
          } else {
            // not used just in case
            var b = localStorage.getItem("badge_id");
            var wo = localStorage.getItem("wo");
            this.$store.dispatch("getWorkOrder", { badge_id: b, wo: wo });
          }
          break;
        case "setLogout":
          localStorage.clear();
          this.$router.push({ name: "Login" });
          break;
      }
    });
  },
  beforeMount() {},
  async mounted() {
    this.workstation_id = localStorage.workstation_id;
    this.operator_name = localStorage.operator_name;
    this.workstation = localStorage.workstation;
    this.subsidiary = localStorage.subsidiary;
    var today = new Date().getDay();

    if(this.subsidiary==="13"){
this.PRP= true;
    }else if(this.subsidiary==="14"){
      this.Allumines= true;
    }else if(this.subsidiary==="15" || this.subsidiary==="16" || this.subsidiary==="17" || this.subsidiary==="18"){
      this.MGP= true;
    }
    //Find days

    //If Monday Show Friday's button
    if (today == 1) {
      this.week_days[0].disabled = false;
    } else {
      this.week_days.forEach((el) => {
        if (el.day == -2) return;
        else if (el.day < today) {
          el.disabled = false;
        }
      });
    }
  },
  methods: {
    release_type() {
      // this.released_dialog = true;
    },
    wo_batch_func() {
      this.wo_batch = !this.wo_batch;
    },
    async endBatch(bussines_function, batch_id, started_time) {
      this.batch_start_time = started_time;
      this.batch_type = "End";
      this.b_func = bussines_function;
      this.spinner = true;
      this.released_dialog = false;
      this.batch_id = null;
      try {
        await this.$store.dispatch("batchendWorkorders", {
          batch_id,
        });
        this.batch_id = batch_id;
        this.show_releasedWorkOrders = true;
      } catch (err) {
        this.workOrderErr = "Issue with getting orders. Contact Lead.";
      }
      this.spinner = false;
    },
    async runBatch(bussines_function) {
      this.b_func = bussines_function;
      this.spinner = true;
      this.released_dialog = false;
      this.workOrderErr = null;
      try {
        await this.$store.dispatch("getBatchWorkOrders", {
          type: "getBatchWorkOrders",
          entry_time: this.entry_time.toISOString(), //WO72461
          bussines_function: bussines_function,
        });
        this.show_releasedWorkOrders = true;
      } catch (err) {
        this.workOrderErr = "Issue with getting orders. Contact Lead.";
      }
      this.spinner = false;
    },
    async getWorkOrderBatches(input_date) {
      this.spinner = true;
      // input_date = 38;
      this.start_batch = true;
      const getTimeStamp = (day) => {
        var curr = new Date();
        return new Date(curr.setDate(curr.getDate() - curr.getDay() + day));
        // ​var date = new Date();
        // var yesterday = curr - 1000 * 60 * 60 * 24 * 42;
        // return new Date(yesterday);
      };
      var entry_time = getTimeStamp(input_date);
      this.entry_time = entry_time;
      try {
        var data_days = await this.$store.dispatch("getStartEndBatchDays", { type: "getBatchDays", entry_time: entry_time.toISOString() });
        data_days.forEach((el) => {
          var _t = this.ordertype.find((tl) => tl.bussines_function.toString() === el.bussines_function);
          _t.started = true;
          _t.completed = el.completed;
          _t.batch_id = el.batch_id;
          _t.started = el.started;
        });

        this.released_dialog = true;
      } catch (err) {
        this.workOrderErr = "Issue with getting orders. Contact Lead.";
      }
      this.spinner = false;
    },
    close_wotable() {
      this.show_table = false;
      this.show_releasedWorkOrders = false;
    },
    async show_wotable() {
      this.spinner = true;

      this.active_wo = await this.$store.dispatch("getInprocessWorkOrders");
      this.spinner = false;
      this.show_table = true;
    },
    fetchWO() {
      // var last_check = localStorage.last_check;
      // if (last_check)
      var badge_id = localStorage.badge_id;
      var wo = localStorage.wo;
      this.$store.dispatch("fetchWO", { wo: wo, badge_id: badge_id });
      this.wo_obj = null;
      this.$refs.wodisplayref.update();
    },
    async logout() {
      var login_id = localStorage.login_id;
      var ret_obj = {
        login_id: login_id,
      };

      if (localStorage.wo && localStorage.workstation_id != "1237674") {
        var curr_mop_id = this.$refs.wodisplayref.returnMopID();
        ret_obj.id = curr_mop_id;
        ret_obj.next_travel_state = "9999";
        ret_obj.curr_travel_state = "3";
      } else {
        ret_obj.next_travel_state = "8888";
      }
      // debugger
      this.$store.dispatch("logoutWorkOrderMOP", ret_obj);
      var _t = localStorage.prev_workstation_id;
      localStorage.clear();
      localStorage.setItem("prev_workstation_id", _t);
      this.$router.push({ name: "Login" });
      // this.$router.push({ name: "login" });
      // localStorage.clear();
      // this.$router.push({ name: "Dashboard" });
    },
    start_stop_spinner() {
      this.spinner = !this.spinner;
    },
    start_stop_spinner_end() {
      this.show_mop_backflush = true;
      this.spinner = !this.spinner;
    },
    foundWO(value) {
      this.wo_obj = value.workorder;
      localStorage.wo = this.wo_obj.name;
    },
    removeWO() {
      localStorage.removeItem("wo");
      this.wo_obj = null;
      this.show_mop_backflush = false;
      this.spinner = false;
      this.show_success = false;
      // this.start_stop_spinner();
    },
    removeWOCutting(wo) {
      localStorage.removeItem("wo");
      this.wo_obj = null;
      this.show_mop_backflush = false;
      var badge_id = localStorage.getItem("badge_id");
      this.spinner = true;
      this.$store.dispatch("getWorkOrder", { badge_id: badge_id, wo: wo });
      localStorage.removeItem("wo");
      this.wo_obj = null;
      this.playSound();
    },
    updatedMOP() {
      localStorage.removeItem("wo");
      this.wo_obj = null;
      this.show_success = true;
      this.prev_wo = localStorage.prev_wo;
      this.spinner = true;
      setTimeout(() => {
        this.spinner = false;
        this.playSound();
      }, 0);
    },
    playSound() {
      var audio = document.createElement("AUDIO");
      document.body.appendChild(audio);
      audio.src = "https://s3.us-west-1.amazonaws.com/traveler.prpseats.com/ding.mp3";
      audio.play();
    },
    editShowSuccess() {
      this.show_success = false;
    },
  },
};
</script>
<style lang="scss">

.rushbtn{
  border: 10px solid hotpink!important;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
  background: white;
}
.image {
  display: block;
  margin: auto;
  // margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 3px;
  width: auto;
  height: 70px;
}
.bold {
  font-weight: 900;
  z-index: 9;
}
.bottom-button {
  background-color: #1e60ae;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: none;
  color: white;
  padding: 15px 65px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.spinner {
  height: 10vh;
  text-align: center;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}
.blink_me {
  text-align: center;
  color: crimson;
  font-weight: 900;
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.btn-bot {
  position: absolute;
  margin-left: -50px;
  left: 50%;
  width: 100px;
  bottom: 0px;
}

.gray-background {
  background-color: gray !important;
}

.btn-red {
  background-color: orangered !important;
}
</style>
