<template>
  <div class="workorder-display">
    <!-- <h1>Work Order Details</h1> -->
    <v-row>
      <v-col>
        <h2>
          {{ workorder.name }}
          [
          <span> {{ workorder.wo_status }} </span>
          <!-- <span> {{ workorder.wo_status }} </span>
      <span> {{ workorder.wo_status }} </span> -->
          ]
        </h2>
        <WorkOrderInfo :workorder="workorder" />
      </v-col>
      <v-col>
        <!-- <BaseTime class="m-auto" :curr_operation="curr_operation" /> -->
        <!-- <CurrentOperationState :curr_operation="curr_operation" /> -->
        <BaseTime class="m-auto" style="margin-top: 40px" :curr_operation="curr_operation" :quantity_left="quantity_left" :quantity_completed="quantity_completed" />
        <div style="text-align: center">
          <!-- <span>Quantity Left: {{ quantity_left }} </span> -->
        </div>

        <div style="width: 100%; text-align: center">
          <input class="input m-auto wo-display" style="color:transparent;" v-model="wo_scanner_input" v-on:keyup.enter="wo_scan_handle_submit()" placeholder="" />
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="400" height="300">
      <v-card height="300" style="display: flex; justify-content: center; align-items: center;">
        <!-- <div style="display: flex; justify-content: center; align-items: center;"> -->
        <v-col style="">
          <v-row>
            <v-card-title class="text-h5" style="word-break: break-word; text-align: center; margin: auto;font-size: 22px!important;"> Did you finish the the Quantity of {{ workorder.operation_quantity_remaining }}?</v-card-title>
          </v-row>
          <v-row class="justify-space-between" style="margin:auto; width: 90%;">
            <v-col>
              <button
                class="submitBtn"
                style="background-color:crimson; border:none;"
                @click="
                  dialog = false;
                  dialog_2 = true;
                "
              >
                No
              </button>
            </v-col>
            <v-col>
              <button class="submitBtn" @click="handle_dialog_submit(workorder.operation_quantity_remaining)">Yes</button>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_2" persistent max-width="400" height="300">
      <v-card height="300" style="display: flex; justify-content: center; align-items: center;">
        <!-- <div style="display: flex; justify-content: center; align-items: center;"> -->
        <v-col style="">
          <v-row>
            <v-card-title class="text-h5" style="word-break: break-word; text-align: center; margin: auto;">
              Enter Completed Quantity...
            </v-card-title>
          </v-row>
          <v-row class="justify-space-between" style="margin:auto; width: 90%;">
            <v-col>
              <v-icon @click="count--" style="font-size: 90px; color: crimson;">mdi-minus-circle</v-icon>
            </v-col>
            <v-col style="margin:auto; text-align:center;">
              <h3 style="font-size: 30px;">{{ count }}</h3>
            </v-col>
            <v-col>
              <v-icon style="font-size: 90px; color: #5cbf2a;" @click="count++">mdi-plus-circle</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <button class="submitBtn" @click="handle_dialog_submit(count)">Continue</button>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
  <!-- ADD AFTER <td>Order Comments: {wo_obj.workorder.order_comments}</td> -->
</template>
<script>
import CurrentOperationState from "../components/currentOperation";
import WorkOrderInfo from "../components/WorkOrderInfo";
import BaseTime from "./BaseTime.vue";
export default {
  name: "workOrderDisplay",
  components: {
    CurrentOperationState,
    WorkOrderInfo,
    BaseTime,
  },
  data: () => ({
    dialog: false,
    dialog_2: false,
    count: 0,
    workstation: null,
    isPaused: false,
    is_first_op: false,
    is_last_state: false,
    wo_scanner_input: null,
    _next_op: null,
    wo_obj: null,
    curr_operation: null,
    expanded: [],
    quantity_left: 0,
    quantity_completed: 0,
    NOW: null,
    headers: [
      {
        text: "Operation",
        value: "op",
      },
      {
        text: "Operator",
        value: "operator",
      },
      {
        text: "Start Time",
        value: "entry_timestamp",
      },
      {
        text: "End Time",
        value: "exit_timestamp",
      },
      {
        text: "State",
        value: "state",
      },
      {
        text: "Actions",
        value: "actions",
      },
      // { text: "", value: "data-table-expand" },
    ],
  }),
  props: ["workorder"],
  filters: {
    stateText: function(value) {
      value = Number(value);
      switch (value) {
        case 1:
          return "Ready";
        case 2:
          return "Working";
        case 3:
          return "Paused";
        case 4:
          return "Canceled";
        case 5:
          return "Finished";
      }
    },
  },
  watch: {
    count: {
      handler(val) {
        if (val < 0) {
          this.count = 0;
        } else if (this.workorder.operation_quantity_remaining < val) {
          this.count = this.workorder.operation_quantity_remaining;
        }
      },
      deep: true,
    },
  },
  //Change
  mounted() {
    this.quantity_left = Number(this.workorder.operation_quantity_remaining);
    this.NOW = new Date(this.workorder.NOW);
    var _t = this.workorder.curr_operation_rate.runrate;
    this.workorder.curr_operation_rate.runrate = _t * this.workorder.quantity;
    this.curr_operation = {
      ...this.workorder.moperation,
      ...this.workorder.curr_operation_rate,
      now: this.NOW,
    };
    this.workstation = localStorage.workstation ? localStorage.workstation : null;
    setInterval(() => this.focusInput(), 100);
    setTimeout(() => {
      const shippingGroup = "1237688";
      const receivingGroup = "1237684";
      if (localStorage.workstation_id == shippingGroup || localStorage.workstation_id == receivingGroup  ) {
        this.wo_scanner_input = localStorage.getItem("wo")
        this.dialog = true;
      }
    });
  
    
  },
  methods: {
    focusInput() {
      // if (document.activeElement.tagName === "BUTTON"){
      //   document.activeElement.blur();
      //   return;
      // }
      if (this.dialog || this.dialog_2) return;
      else if (document.activeElement.tagName === "INPUT") return;
      setTimeout(() => {
        var element = document.getElementsByClassName("wo-display")[0];
        if (!element) return;
        element.readOnly = true;
        element.focus();
        setTimeout(function() {
          document.getElementsByClassName("wo-display")[0].readOnly = false;
        }, 50);
      }, 0);
    },
    update() {
      this.quantity_left = Number(this.workorder.quantity);
      this.NOW = new Date(this.workorder.NOW);
      // this.quantity_completed = Number(this.workorder.quantity) - this.quantity_left;
      // if (_next_op == null) _next_op = { id: "NA" };
      // this._next_op = _next_op;
      var _t = this.workorder.curr_operation_rate.runrate;
      this.workorder.curr_operation_rate.runrate = _t * 100;
      this.curr_operation = {
        ...this.workorder.moperation,
        ...this.workorder.curr_operation_rate,
        now: this.NOW,
      };
      this.workstation = localStorage.workstation ? localStorage.workstation : null;
    },
    returnMopID() {
      return this.curr_operation.id;
    },
    qcholdWO() {
      // var r = confirm("Are you sure you want to put item in QC Hold?");
      // if (r == true) {
      var ret_obj = {
        wc_login: localStorage.login_id,
        next_travel_state: "4",
        id: this.curr_operation.id,
        workorder: this.workorder.id,
      };
      this.$emit("spinner");
      this.$store.dispatch("pauseWorkOrderMOP", ret_obj);
      // } else {
      //   return;
      // }
    },
    //change
    pauseWO() {
      // var r = confirm("Are you sure you want to pause operation?");
      // if (r == true) {
      var ret_obj = {
        badge_id: localStorage.badge_id,
        next_travel_state: "3",
        pause_reason: "Pause",
        id: this.curr_operation.id,
      };
      this.$emit("spinner");
      this.$store.dispatch("pauseWorkOrderMOP", ret_obj);
      // } else {
      //   return;
      // }
    },
    async wo_scan_handle_submit() {
      console.log(this.wo_scanner_input);
      if (this.wo_scanner_input == null) return;
      else if (this.wo_scanner_input == "@PAUSE@") {
        this.pauseWO();
      } else if (this.wo_scanner_input == "@QCHOLD@") {
        this.qcholdWO();
      } else if (this.wo_scanner_input.trim() == localStorage.badge_id || this.wo_scanner_input.trim() == "1234") {
        this.$emit("logout");
      } else {
        if (this.wo_scanner_input.trim() !== localStorage.getItem("wo")) {
          var work_station = localStorage.workstation;
          if (work_station.includes("Cutting")) {
            //Start the new work order
            this.$emit("removeWOCutting", this.wo_scanner_input.trim());
            return;
          } else {
            this.wo_scanner_input = null;
          }
        } else {
          var work_station = localStorage.workstation;
          //Check Logic For Cutting
          if (work_station.includes("Cutting")) {
            //If cutting send full amount
            this.handle_dialog_submit(this.workorder.operation_quantity_remaining);
          }
          // else if (this.workorder.operation_quantity_remaining == 1) {
          //   this.handle_dialog_submit(1);
          // }
          else this.dialog = true;
        }
      }
    },
    handle_dialog_submit(val) {
      this.wo_scanner_input = this.wo_scanner_input.trim();
      // this.$emit("spinner");
      var ret_obj = {
        wc_login: localStorage.login_id,
        badge_id: localStorage.badge_id,
        next_travel_state: "5",
        work_order: this.workorder.name,
        id: this.curr_operation.id,
        curr_op_pos_id: this.workorder.curr_op_id,
        quantity_completed: val,
      };
      // var ret = await updateMOP();
      // if (this.is_last_state) {
      //   setTimeout(() => {
      //     this.$emit("spinner_end");
      //   }, 0);
      // } else {
      // setTimeout(() => {
      //   this.$emit("spinner");
      // }, 0);
      // }
      // debugger;
      this.$store.dispatch("putWorkOrderMOP", ret_obj);
      this.$emit("updatedMOP");
      // setTimeout(() => {
      //   this.$emit("spinner");
      // }, 0);
    },
    stateText(value) {
      value = Number(value);
      switch (value) {
        case 1:
          return "Ready";
        case 2:
          return "Working";
        case 3:
          return "QCHOld";
        case 4:
          return "Finished";
      }
    },
  },
};
</script>
<style lang="scss">
.v-btn--active::before {
  opacity: 0 !important;
}
.submitBtn {
  margin: auto;
  width: 100%;
  background-color: #5cbf2a;
  border-radius: 28px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 16px 31px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
}
.input {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: 0px solid gray;
  text-align: center;
  caret-color: transparent;
  color: black;
  width: 90px;
  height: 30px;
  padding-left: 10px;
  outline: none !important;
  margin-top: 10px;
}

.workorder-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.workorder-display {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: left;
  min-width: 900px;
  max-width: 1000px;
  margin: auto;
}
.state-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 10px;
  border: solid 1px whitesmoke;
  margin-bottom: 10px;
  th {
    text-align: inherit;
  }
  .isNotStartedState {
    background-color: #eeeeee;
  }
}
//Disable Hover Events
tr {
  pointer-events: none;
}
</style>
