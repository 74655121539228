<template>
  <div>
    <div class="workOrd-wrapper" v-if="!spinner">
      <input class="input-login search-wo" v-on:keyup.enter="handleSubmit()" v-model="workorder" style="" />
      <h2 class="blink_me" style="color: #1e60ae;">Scan Work Order</h2>
    </div>
    <div v-else class="spinner">
      <v-progress-circular indeterminate color="rgb(30 96 174)"></v-progress-circular>
    </div>
  </div>
</template>
<script>
export default {
  name: "WorkOrderSearch",
  data: () => ({
    spinner: false,
    workorder: null,
    workOrderErr: null,
  }),
  mounted() {
    // document.getElementById("search-wo").focus();
    // }, 0);
    // this.hideKeyboard(document.getElementById("search-wo"));
    // setTimeout(() => {
    //   var element = document.getElementById("search-wo");
    //   element.attr("readonly", "readonly"); // Force keyboard to hide on input field.
    //   element.attr("disabled", "true"); // Force keyboard to hide on textarea field.
    //   setTimeout(function() {
    //     element.blur(); //actually close the keyboard
    //     // Remove readonly attribute after keyboard is hidden.
    //     element.removeAttr("readonly");
    //     element.removeAttr("disabled");
    //   }, 100);
    // }, 100);
    setInterval(() => this.focusInput(), 100);
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    focusInput() {
      if (document.activeElement.tagName === "INPUT") return;
      setTimeout(() => {
        var element = document.getElementsByClassName("search-wo")[0];
        // console.log(element)
        if (!element) return;
        element.readOnly = true;
        element.focus();
        setTimeout(function() {
          document.getElementsByClassName("search-wo")[0].readOnly = false;
        }, 50);
      }, 0);
    },
    async handleSubmit() {
      if (this.workorder == null) return;
      this.$emit("editShowSuccess");
      this.workorder = this.workorder.trim();
      this.workOrderErr = null;
      var badge_id = localStorage.getItem("badge_id");
      // const workorder_obj = await this.getWO(badge_id, this.workorder);
      if (this.workorder == badge_id || this.workorder.trim() == "1234") {
        //  localStorage.clear();
        this.$emit("logout");
      } else {
        this.$emit("spinner");
        localStorage.prev_wo = this.workorder.trim();
        await this.$store.dispatch("getWorkOrder", { badge_id: badge_id, wo: this.workorder });
      }
      // if (workorder_obj.success) {
      //   console.log("Successfully retrieved: " + workorder_obj.workorder.name);
      //   this.$emit("workOrder", workorder_obj);
      //   // this.updateMOP()
      // } else {
      //   console.log("Invalid WO specified.");
      //   this.workOrderErr = "Invalid Work Order specified";
      // }
    },
  },
};
</script>
<style lang="scss">
.workOrd-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center !important;
  margin-top: 20px;
}

.input-login {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 15px;
  border: 0px solid gray;
  color: black;
  width: 269.75px;
  // height: 56px;
  padding-left: 10px;
  outline: none !important;
  margin-top: 10px;
  caret-color: transparent;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
