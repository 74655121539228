<template>
  <div style="width:100%;" class="curr-op">
    <BaseTime class="m-auto" :curr_operation="curr_operation" />
    <!-- <div style="text-align: center;">
      <v-icon style="font-size: 80px; color: #f7b500;">mdi-pause-circle-outline</v-icon>
    </div> -->
    <!--
      v-if="state === 'Ready'"
      <v-icon style="font-size: 80px; color: green;" >mdi-play-circle-outline</v-icon>
      v-else-if="state != 'Working'"
      <v-icon style="font-size: 80px; color: #f7b500;" >mdi-pause-circle-outline</v-icon>
      v-else-if="state !== 'Working'"
      <v-icon style="font-size: 80px; color: green;" >mdi-check-circle-outline</v-icon>
        v-else-if="state !== 'Working
      <v-icon style="font-size: 80px; color: crimson;">mdi-cancel</v-icon>
    </div> -->
  </div>
</template>
<script>
import BaseTime from "./BaseTime.vue";
export default {
  props: ["curr_operation"],
  components: {
    BaseTime,
  },
  data: () => ({
    state: "Ready",
    // tm
  }),
  mounted() {
    // t
  },
  methods: {
    async qcHold() {
      // cancel request
    },
    async pause() {
      // cancel request
    },
  },
};
</script>
<style lang="scss">
.m-auto {
  margin: auto;
}
</style>
